<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2020-11-24 15:47:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="box-create">
        <div class="box-title">{{ id ? $t('system.country.edit') : $t('system.country.add')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="6">
                    <el-form-item :label="$t('system.country.name')" prop="countryNameInternational" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.countryNameInternational"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.region')" prop="regionID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select v-model="form.regionID" >
                            <el-option
                                    v-for="item in regions"
                                    :key="item.regionID"
                                    :label="item.regionNameInternational"
                                    :value="item.regionID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminCountry'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        return {
            form:{
            },
            id: this.$route.query.id,
            regions:[],
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.id){
            this.systemTCountryGetById()
        }
        this.getAllRegion()
    },
    methods: {

        /*获取所有区域数据*/
        /*获取所有区域*/
        async getAllRegion(){
            let res = await this.API.systemRegionsGetAll({})
            this.regions = res;
        },


        /*获取详情*/
        async systemTCountryGetById() {
            let res = await this.API.systemTCountryGetById({
                countryID: this.id
            })
            this.form = res;
        },

        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.id){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*更新*/
        async update(data){
            let res = await this.API.systemTCountryUpdate(data)
            this.showMessage(res)

        },

        /*创建*/
        async save(data){
            let res = await this.API.systemTCountrySave(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminCountry'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },
    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
}
</style>

